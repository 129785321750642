import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { FilesInputSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';

const lottie: InputAttribute<FilesInputSettings> = {
    label: Translation.get('dynamicLayers.labels.lottie', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.lottie', 'template-designer'),
    attribute: 'lottie',
    defaultSetting: 'files',
    settings: {
        files: {
            ...baseSettings,
            type: 'files',
            canUpload: true,
            fileType: ['json'],
            multiple: false
        }
    }
};

export { lottie };
