import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { AssetGalleryInputSettings, AssetSelectorSettings, DefaultSettingsFunction } from 'components/template-designer/types/dynamicLayer.type';
import defaultRatios from 'components/assets/AssetGalleryCropper/data/default-ratios';
import Setup from 'components/data/Setup';
import { AI_MODULE, UNSPLASH_MODULE } from 'components/template-designer/constants';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import Src from 'components/template-designer/types/src.type';
import { generateKey } from 'components/template-designer/utils/generateKey';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { baseSettings } from './base-settings';
import { ImageSettingsForm } from '../forms/image-settings-form';
import { PredefinedImagesSettingsForm } from '../forms/predefined-images-settings-form';

const backgroundImage: InputAttribute<DefaultSettingsFunction<AssetGalleryInputSettings> | DefaultSettingsFunction<AssetSelectorSettings>> = {
    label: Translation.get('dynamicLayers.labels.backgroundImage', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.backgroundImage', 'template-designer'),
    attribute: 'backgroundImage',
    settings: {
        assetGalleryInput: () => {
            const hasAI = Setup.hasModule(AI_MODULE);
            const compressorEnabled = DynamicLayerHelpers.isCompresserEnabled();

            const settings: AssetGalleryInputSettings = {
                ...baseSettings,
                type: 'assetGalleryInput',
                fileType: ['image'],
                canUpload: true,
                canUseAiContent: hasAI,
                canRemoveBackground: hasAI,
                canRemoveObjects: hasAI,
                canMagicEdit: hasAI,
                canOutpaint: hasAI,
                canUseUnsplash: Setup.hasModule(UNSPLASH_MODULE),
                canUseAprimo: false,
                useCropper: true,
                canUseContentSpace: true,
                mode: 'free',
                useCompressor: compressorEnabled,
                useCustomCompressor: compressorEnabled,
                ratios: defaultRatios,
                ...(getTemplateData('templateData.type') === 'displayAdDesigned' && {
                    maxOutputWidth: 970,
                    maxOutputHeight: 600
                })
            };

            return settings;
        },
        assetSelector: (selectedLayer, attribute) => {
            const input: AssetSelectorSettings = {
                ...baseSettings,
                type: 'assetSelector',
                fileType: 'image',
                canUpload: false,
                showTitles: false,
                sourceData: []
            };

            const image = DynamicLayerHelpers.getGeneralTemplateValue(attribute, input, selectedLayer) as Src | undefined;

            if (image) {
                input.sourceData.push({
                    id: generateKey(),
                    title: image.title,
                    url: image.url
                });
            }

            return input;
        }
    },
    updateSettings: (selectedLayer, attribute, oldSettings) => {
        const input = cloneDeep(oldSettings) as AssetSelectorSettings;

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, oldSettings, selectedLayer) as Src | undefined;
        if (!generalTemplateValue) return input;

        if (!input.sourceData) input.sourceData = [];
        input.sourceData[0] = {
            id: generateKey(),
            title: generalTemplateValue.title,
            url: generalTemplateValue.url
        };
        return input;
    },
    defaultSetting: 'assetGalleryInput',
    editForm: () => (
        <>
            <ImageSettingsForm />
            <PredefinedImagesSettingsForm updateSettings={backgroundImage.updateSettings} attribute={backgroundImage.attribute} disableIndex={0} />
        </>
    )
};

export { backgroundImage };
