import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { ButtonGroupSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { ButtonGroupForm } from '../forms/button-group-form';
import { baseSettings } from './base-settings';

const horizontalAlignOptions = [
    { key: 'none', value: Translation.get('general.position.none', 'template-designer') },
    { key: 'left', value: Translation.get('general.position.left', 'template-designer') },
    { key: 'center', value: Translation.get('general.position.center', 'template-designer') },
    { key: 'right', value: Translation.get('general.position.right', 'template-designer') }
];

const horizontalAlign: InputAttribute<ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.horizontalAlign', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.horizontalAlign', 'template-designer'),
    attribute: 'horizontalAlign',
    settings: {
        buttonGroup: {
            ...baseSettings,
            type: 'buttonGroup',
            options: horizontalAlignOptions
        }
    },
    defaultSetting: 'buttonGroup',
    editForm: () => (
        <ButtonGroupForm
            attribute="horizontalAlign"
            updateSettings={horizontalAlign.updateSettings}
            options={[
                {
                    label: Translation.get('general.position.none', 'template-designer'),
                    value: 'none'
                },
                {
                    label: Translation.get('general.position.left', 'template-designer'),
                    value: 'left'
                },
                {
                    label: Translation.get('general.position.center', 'template-designer'),
                    value: 'center'
                },
                {
                    label: Translation.get('general.position.right', 'template-designer'),
                    value: 'right'
                }
            ]}
        />
    ),
    updateSettings: (selectedLayer, attribute, settings) =>
        DynamicLayerHelpers.updateSettingsButtonGroup(selectedLayer, attribute, settings, horizontalAlignOptions)
};

export { horizontalAlign };
