import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { FeedItemSelectorSettings } from 'components/template-designer/types/dynamicLayer.type';
import { FeedItemSelectorSettingsForm } from '../forms/feed-item-selector-settings-form';
import { baseSettings } from './base-settings';

const feedSelectorInput: InputAttribute<FeedItemSelectorSettings> = {
    label: Translation.get('dynamicLayers.labels.feedItemSelector', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.feedItemSelector', 'template-designer'),
    attribute: 'feedSelectorInput',
    settings: {
        feedSelectorInput: {
            ...baseSettings,
            type: 'feedItemSelector',
            display: 'grid',
            usage: 'custom'
        }
    },
    defaultSetting: 'feedItemSelector',
    editForm: () => <FeedItemSelectorSettingsForm />
};

export { feedSelectorInput };
