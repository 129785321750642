import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { SwitchSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';

const visibility: InputAttribute<SwitchSettings> = {
    label: Translation.get('dynamicLayers.labels.visibility', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.visibility', 'template-designer'),
    attribute: 'visibility',
    settings: {
        switch: {
            ...baseSettings,
            type: 'switch',
            isVisible: true,
            inverted: true,
            defaultValue: false
        }
    },
    defaultSetting: 'switch'
};

export { visibility };
