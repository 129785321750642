import React from 'react';
import Translation from 'components/data/Translation';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';

const frameFitting: InputAttribute<SelectSettings> = {
    label: Translation.get('dynamicLayers.labels.frameFitting', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.frameFitting', 'template-designer'),
    attribute: 'frameFitting',
    settings: {
        select: {
            type: 'select',
            options: [
                {
                    key: 'TOP_LEFT_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.lefttop', 'template-designer')
                },
                {
                    key: 'CENTER_LEFT_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.leftcenter', 'template-designer')
                },
                {
                    key: 'BOTTOM_LEFT_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.leftbottom', 'template-designer')
                },
                {
                    key: 'TOP_RIGHT_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.righttop', 'template-designer')
                },
                {
                    key: 'CENTER_RIGHT_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.rightcenter', 'template-designer')
                },
                {
                    key: 'BOTTOM_RIGHT_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.rightbottom', 'template-designer')
                },
                {
                    key: 'TOP_CENTER_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.centertop', 'template-designer')
                },
                {
                    key: 'CENTER_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.centercenter', 'template-designer')
                },
                {
                    key: 'BOTTOM_CENTER_ANCHOR',
                    value: Translation.get('sidebarRight.inputs.imagePositionOptions.centerbottom', 'template-designer')
                }
            ]
        }
    },
    defaultSetting: 'radioList',
    editForm: () => (
        <>
            <MultiSelectFormDynamic inputType="text" attribute={frameFitting.attribute} disabledIndex={0} />
        </>
    )
};

export { frameFitting };
