import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { ButtonGroupSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { ButtonGroupForm } from '../forms/button-group-form';
import { baseSettings } from './base-settings';

const textDecorationOptions = [
    { key: 'none', value: Translation.get('dynamicLayers.labels.textDecorationOptions.none', 'template-designer') },
    { key: 'overline', value: Translation.get('dynamicLayers.labels.textDecorationOptions.overline', 'template-designer') },
    { key: 'line-through', value: Translation.get('dynamicLayers.labels.textDecorationOptions.lineThrough', 'template-designer') },
    { key: 'underline', value: Translation.get('dynamicLayers.labels.textDecorationOptions.underline', 'template-designer') }
];

const textDecoration: InputAttribute<ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.textDecoration', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.textDecoration', 'template-designer'),
    attribute: 'textDecoration',
    settings: {
        buttonGroup: {
            ...baseSettings,
            type: 'buttonGroup',
            options: textDecorationOptions
        }
    },
    defaultSetting: 'buttonGroup',
    editForm: () => (
        <ButtonGroupForm
            attribute="textDecoration"
            updateSettings={textDecoration.updateSettings}
            options={[
                {
                    icon: 'close',
                    label: Translation.get('dynamicLayers.labels.textDecorationOptions.none', 'template-designer'),
                    value: 'none'
                },
                {
                    icon: 'format_overline',
                    label: Translation.get('dynamicLayers.labels.textDecorationOptions.overline', 'template-designer'),
                    value: 'overline'
                },
                {
                    icon: 'strikethrough_s',
                    label: Translation.get('dynamicLayers.labels.textDecorationOptions.lineThrough', 'template-designer'),
                    value: 'line-through'
                },
                {
                    icon: 'format_underlined',
                    label: Translation.get('dynamicLayers.labels.textDecorationOptions.underline', 'template-designer'),
                    value: 'underline'
                }
            ]}
        />
    ),
    updateSettings: (selectedLayer, attribute, settings) =>
        DynamicLayerHelpers.updateSettingsButtonGroup(selectedLayer, attribute, settings, textDecorationOptions)
};

export { textDecoration };
