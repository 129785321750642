import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { AssetGalleryInputSettings } from 'components/template-designer/types/dynamicLayer.type';
import defaultRatios from 'components/assets/AssetGalleryCropper/data/default-ratios';
import { VideoSettingsForm } from '../forms/video-settings-form';
import { baseSettings } from './base-settings';

const video: InputAttribute<AssetGalleryInputSettings> = {
    label: Translation.get('dynamicLayers.labels.video', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.video', 'template-designer'),
    attribute: 'video',
    settings: {
        assetGalleryInput: {
            ...baseSettings,
            type: 'assetGalleryInput',
            fileType: ['video'],
            canUpload: true,
            canUseImageToVideo: true,
            cropMode: 'ratioBased',
            useCropper: true,
            canUseContentSpace: true,
            canSkipCropper: true,
            ratios: defaultRatios
        }
    },
    defaultSetting: 'assetGalleryInput',
    editForm: () => <VideoSettingsForm />
};

export { video };
