import React from 'react';
import { ButtonGroupSettings, RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import cloneDeep from 'helpers/cloneDeep';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { ButtonGroupForm, ButtonGroupFormOption } from '../forms/button-group-form';
import { baseSettings } from './base-settings';

const justifyContentSettingOptions = [
    { key: 'flex-start', value: Translation.get('general.position.top', 'template-designer') },
    { key: 'center', value: Translation.get('general.position.center', 'template-designer') },
    { key: 'flex-end', value: Translation.get('general.position.bottom', 'template-designer') }
];

const justifyContentFormOptions: ButtonGroupFormOption[] = [
    {
        value: 'flex-start',
        label: Translation.get('dynamicLayers.labels.justifyContentOptions.start', 'template-designer'),
        icon: 'align-vertical-top'
    },
    {
        value: 'center',
        label: Translation.get('dynamicLayers.labels.justifyContentOptions.center', 'template-designer'),
        icon: 'align-vertical-center'
    },
    {
        value: 'flex-end',
        label: Translation.get('dynamicLayers.labels.justifyContentOptions.end', 'template-designer'),
        icon: 'align-vertical-bottom'
    },
    {
        value: 'space-between',
        label: Translation.get('dynamicLayers.labels.justifyContentOptions.between', 'template-designer'),
        icon: 'justify-flex-space-between'
    },
    {
        value: 'space-evenly',
        label: Translation.get('dynamicLayers.labels.justifyContentOptions.evenly', 'template-designer'),
        icon: 'justify-flex-space-evenly'
    }
];

const justifyContent: InputAttribute<SelectSettings | RadioListSettings | ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.alignVertical', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.justifyContent', 'template-designer'),
    attribute: 'justifyContent',
    settings: {
        buttonGroup: {
            ...baseSettings,
            type: 'buttonGroup',
            options: justifyContentSettingOptions
        }
    },
    defaultSetting: 'buttonGroup',
    updateSettings: (selectedLayer, attribute, oldSettings) => {
        const input = cloneDeep(oldSettings) as SelectSettings | RadioListSettings | ButtonGroupSettings;
        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, oldSettings, selectedLayer) as string | undefined;
        const optionExists = input.options.some((option) => option.key === generalTemplateValue);

        if (!optionExists) {
            const newOption = justifyContentSettingOptions.find((option) => option.key === generalTemplateValue);
            if (newOption) {
                const newOptions = cloneDeep(input.options);
                newOptions.push(newOption);
                const sortedNewOptions: SelectSettings['options'] = [];

                justifyContentSettingOptions.forEach((option) => {
                    if (newOptions.some((newOption) => newOption.key === option.key)) {
                        sortedNewOptions.push(option);
                    }
                });

                input.options = sortedNewOptions;
            }
        }

        return input;
    },
    editForm: () => (
        <>
            <ButtonGroupForm attribute="justifyContent" updateSettings={justifyContent.updateSettings} options={justifyContentFormOptions} />
        </>
    )
};

export { justifyContent };
