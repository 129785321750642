import React from 'react';
import { RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { webSafeFonts } from 'components/template-designer/config/fonts';
import cloneDeep from 'helpers/cloneDeep';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { GoogleFonts } from 'components/template-designer/models/google-fonts';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import BrandGuide from 'components/template-designer/types/brandGuide.type';
import { baseSettings } from './base-settings';
import FontFamilyForm from '../forms/font-family-form';

const fontFamily: InputAttribute<SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.fontFamily', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.fontFamily', 'template-designer'),
    attribute: 'fontFamily',
    settings: {
        select: {
            ...baseSettings,
            type: 'select',
            options: webSafeFonts.map((font) => ({
                key: font.value,
                value: font.label
            }))
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            options: webSafeFonts.map((font) => ({
                key: font.value,
                value: font.label
            }))
        }
    },
    defaultSetting: 'radioList',
    updateSettings: (selectedLayer, attribute, settings) => {
        const input = cloneDeep(settings) as RadioListSettings | SelectSettings;
        if (input.type !== 'select' && input.type !== 'radioList') return input;

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, selectedLayer) as string | undefined;
        if (!generalTemplateValue) return input;

        const generalTemplateValueLabel = (() => {
            const brandGuideFonts = getTemplateData<BrandGuide>('brandGuide').fonts;

            const brandGuideFont = brandGuideFonts.find((font) => font.key === generalTemplateValue);
            if (brandGuideFont) return brandGuideFont.value;

            const standardFonts = webSafeFonts.map((font) => ({ key: font.value, value: font.label }));
            const standardFont = standardFonts.find((font) => font.key === generalTemplateValue);
            if (standardFont) return standardFont.value;

            const googleFonts = GoogleFonts.get().map((font) => ({ key: font.family, value: font.family }));
            const googleFont = googleFonts.find((font) => font.key === generalTemplateValue);
            if (googleFont) return googleFont.value;
        })();

        if (!input.options) input.options = [];
        input.options = input.options.filter((option, index) => option.key !== generalTemplateValue || index === 0);

        input.options[0] = {
            key: generalTemplateValue,
            value: generalTemplateValueLabel
        };
        return input;
    },
    editForm: () => (
        <>
            <FontFamilyForm attribute={fontFamily.attribute} updateSettings={fontFamily.updateSettings} />
        </>
    )
};

export { fontFamily };
