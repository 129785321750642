import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { SwitchSettings } from 'components/template-designer/types/dynamicLayer.type';

const fadeAudio: InputAttribute<SwitchSettings> = {
    label: Translation.get('dynamicLayers.labels.fadeAudio', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.fadeAudio', 'template-designer'),
    attribute: 'fadeAudio',
    settings: {
        switch: {
            type: 'switch',
            isVisible: true,
            inverted: true
        }
    },
    defaultSetting: 'switch'
};

export { fadeAudio };
