import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';

const dropdown: InputAttribute<SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.dropdown', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.feedItemSelector', 'template-designer'),
    attribute: 'dropdown',
    settings: {
        select: {
            ...baseSettings,
            type: 'select',
            setFirstOption: true,
            options: [
                {
                    key: 'Option1',
                    value: 'Option 1'
                }
            ]
        }
    },
    defaultSetting: 'select',
    editForm: () => <MultiSelectFormDynamic inputType="text" attribute={dropdown.attribute} multiline={false} />
};

export { dropdown };
