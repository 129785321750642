import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { NumberSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const positionX: InputAttribute<SliderSettings | NumberSettings> = {
    label: Translation.get('dynamicLayers.labels.positionX', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.positionX', 'template-designer'),
    attribute: 'positionX',
    defaultSetting: 'slider',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            min: -100,
            max: 100,
            defaultValue: 0,
            step: 1,
            inputFieldDisplay: true,
            hideMarks: true,
            marks: false,
            allowZero: true
        },
        number: {
            ...baseSettings,
            type: 'number',
            placeholder: Translation.get('dynamicLayers.labels.positionX', 'template-designer'),
            min: -100,
            max: 100,
            step: 1
        }
    },
    editForm: () => (
        <>
            <NumberSettingsForm unit="px" allowNegative />
        </>
    )
};

export { positionX };
