import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { HiddenSettings } from 'components/template-designer/types/dynamicLayer.type';
import { ParseValueSettingsForm } from '../forms/parse-value-settings-form';

const parseValueText: InputAttribute<HiddenSettings> = {
    label: Translation.get('dynamicLayers.labels.parseValue', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.parseValueText', 'template-designer'),
    attribute: 'parseValue',
    overwriteModel: 'text',
    settings: {
        hidden: {
            type: 'hidden',
            parseValue: ''
        }
    },
    defaultSetting: 'hidden',
    editForm: () => <ParseValueSettingsForm />
};

export { parseValueText };
