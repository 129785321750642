import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { GroupSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';
import { SwitchSettingsForm } from '../forms/switch-settings-form';

const group: InputAttribute<GroupSettings> = {
    label: Translation.get('dynamicLayers.labels.group', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.group', 'template-designer'),
    attribute: 'group',
    settings: {
        subSection: {
            ...baseSettings,
            type: 'switch'
        }
    },
    defaultSetting: 'subSection',
    editForm: () => (
        <SwitchSettingsForm
            label={Translation.get('dynamicLayers.layerEdit.editForms.forms.switchSettings.opened', 'template-designer')}
            setting="opened"
            tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.switchSettings.tooltip', 'template-designer')}
        />
    )
};

export { group };
