import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { CustomInputForm } from '../forms/custom-input-form';

const customInput: InputAttribute<any> = {
    label: Translation.get('dynamicLayers.labels.customInput', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.customInput', 'template-designer'),
    attribute: 'customInput',
    settings: {
        customInput: {
            type: 'slider',
            label: Translation.get('dynamicLayers.labels.customInput', 'template-designer'),
            model: 'customInput',
            allowZero: true,
            min: 50,
            max: 200,
            step: 1,
            hideMarks: true,
            inputFieldDisplay: true,
            marks: false
        }
    },
    defaultSetting: 'customInput',
    editForm: () => <CustomInputForm />
};

export { customInput };
