import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { DividerSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';

const divider: InputAttribute<DividerSettings> = {
    label: Translation.get('dynamicLayers.labels.divider', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.feedItemSelector', 'template-designer'),
    attribute: 'divider',
    settings: {
        divider: {
            ...baseSettings,
            type: 'divider'
        }
    },
    defaultSetting: 'divider'
};

export { divider };
