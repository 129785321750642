import React, { useMemo } from 'react';
import { DynamicLayerInput } from 'components/template-designer/types/dynamicLayer.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Template, { View } from 'components/template-designer/types/template.type';
import Translation from 'components/data/Translation';
import { DynamicLayerInputDialog, Selected } from 'components/template-designer/components/dynamic-layer-input-dialog';
import InputTypeDialog from 'components/template-designer/components/adobe/components/input-type-dialog';

interface Data {
    frameType: View['frameType'];
    dynamicLayers: Template['dynamicLayers'];
    templateType: Template['templateData']['type'];
}

interface Props {
    onSubmit: (selected: Selected) => void;
    onClose: () => void;
}

const DynamicLayerDialog = ({ onSubmit, onClose }: Props) => {
    const { frameType, dynamicLayers, templateType } = useComponentStore<Data>('TemplateDesigner', {
        fields: {
            frameType: 'view.frameType',
            dynamicLayers: 'dynamicLayers',
            templateType: 'templateData.type'
        }
    });

    /**
     * Get already selected inputs.
     */
    const alreadySelected = useMemo(() => {
        const alreadySelected: Selected = {};

        /**
         * Gather all inputs that are already selected.
         * @param inputs - Inputs to check.
         */
        function addSelected(inputs: DynamicLayerInput[]) {
            inputs.forEach((input) => {
                if ('children' in input) addSelected(input.children);
                if (!('layerKey' in input)) return;
                if (!alreadySelected[input.layerKey]) alreadySelected[input.layerKey] = [];
                alreadySelected[input.layerKey].push(input.attribute);
            });
        }

        addSelected(dynamicLayers[frameType]);

        return alreadySelected;
    }, [frameType, dynamicLayers]);

    if (['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType)) {
        return <InputTypeDialog onClose={onClose} templateType={templateType} view="dynamicLayer" />;
    }

    return (
        <DynamicLayerInputDialog
            onClose={onClose}
            onSubmit={onSubmit}
            title={Translation.get('dynamicLayers.dynamicLayerInput.title', 'template-designer')}
            alreadySelected={alreadySelected}
            showFormat
            showLinkedInputs
        />
    );
};

export { DynamicLayerDialog };
