import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { AssetGalleryInputSettings } from 'components/template-designer/types/dynamicLayer.type';
import { UNSPLASH_MODULE } from 'components/template-designer/constants';
import Setup from 'components/data/Setup';
import { AudioSettingsForm } from '../forms/audio-settings-form';
import { baseSettings } from './base-settings';

const audio: InputAttribute<AssetGalleryInputSettings> = {
    label: Translation.get('dynamicLayers.labels.audio', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.audio', 'template-designer'),
    attribute: 'audio',
    defaultSetting: 'assetGalleryInput',
    settings: {
        assetGalleryInput: {
            ...baseSettings,
            type: 'assetGalleryInput',
            fileType: ['audio'],
            canUpload: true,
            canUseContentSpace: true,
            canUseUnsplash: Setup.hasModule(UNSPLASH_MODULE),
            useCropper: true,
            useDurationOfLayer: true
        }
    },
    editForm: () => <AudioSettingsForm />
};

export { audio };
