import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { FilesInputSettings } from 'components/template-designer/types/dynamicLayer.type';

const file: InputAttribute<FilesInputSettings> = {
    label: Translation.get('dynamicLayers.labels.file', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.file', 'template-designer'),
    attribute: 'file',
    defaultSetting: 'files',
    settings: {
        files: {
            type: 'files',
            canUpload: true,
            fileType: ['txt', 'srt'],
            multiple: false
        }
    }
};

export { file };
