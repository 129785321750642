import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { ButtonGroupSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { ButtonGroupForm } from '../forms/button-group-form';
import { baseSettings } from './base-settings';

const borderStyleOptions = [
    { key: 'none', value: Translation.get('dynamicLayers.labels.borderStyleOptions.none', 'template-designer') },
    { key: 'solid', value: Translation.get('dynamicLayers.labels.borderStyleOptions.solid', 'template-designer') },
    { key: 'dotted', value: Translation.get('dynamicLayers.labels.borderStyleOptions.dotted', 'template-designer') },
    { key: 'dashed', value: Translation.get('dynamicLayers.labels.borderStyleOptions.dashed', 'template-designer') }
];

const borderStyle: InputAttribute<ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.borderStyle', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.borderStyle', 'template-designer'),
    attribute: 'borderStyle',
    settings: {
        buttonGroup: {
            ...baseSettings,
            type: 'buttonGroup',
            options: borderStyleOptions
        }
    },
    defaultSetting: 'buttonGroup',
    editForm: () => (
        <ButtonGroupForm
            attribute="borderStyle"
            updateSettings={borderStyle.updateSettings}
            options={[
                {
                    icon: 'close',
                    label: Translation.get('dynamicLayers.labels.borderStyleOptions.none', 'template-designer'),
                    value: 'none'
                },
                {
                    icon: 'horizontal_rule',
                    label: Translation.get('dynamicLayers.labels.borderStyleOptions.solid', 'template-designer'),
                    value: 'solid'
                },
                {
                    icon: 'more_horiz',
                    label: Translation.get('dynamicLayers.labels.borderStyleOptions.dotted', 'template-designer'),
                    value: 'dotted'
                },
                {
                    icon: 'dashed-line',
                    label: Translation.get('dynamicLayers.labels.borderStyleOptions.dashed', 'template-designer'),
                    value: 'dashed'
                }
            ]}
        />
    ),
    updateSettings: (selectedLayer, attribute, settings) =>
        DynamicLayerHelpers.updateSettingsButtonGroup(selectedLayer, attribute, settings, borderStyleOptions)
};

export { borderStyle };
