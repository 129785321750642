import React from 'react';
import { ButtonGroupSettings, RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { MultiSelectForm } from '../forms/multi-select-form';
import { ButtonGroupFormOption } from '../forms/button-group-form';
import { baseSettings } from './base-settings';

const alignItemsSettingOptions = [
    {
        key: 'left top',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.lefttop', 'template-designer')
    },
    {
        key: 'center top',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.centertop', 'template-designer')
    },
    {
        key: 'right top',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.righttop', 'template-designer')
    },
    {
        key: 'left center',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.leftcenter', 'template-designer')
    },
    {
        key: 'center center',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.centercenter', 'template-designer')
    },
    {
        key: 'right center',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.rightcenter', 'template-designer')
    },
    {
        key: 'left bottom',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.leftbottom', 'template-designer')
    },
    {
        key: 'center bottom',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.centerbottom', 'template-designer')
    },
    {
        key: 'right bottom',
        value: Translation.get('sidebarRight.inputs.imagePositionOptions.rightbottom', 'template-designer')
    }
];

const mediaPositionFormOptions: ButtonGroupFormOption[] = alignItemsSettingOptions.map((option) => ({
    value: option.key,
    label: option.value
}));

const objectPosition: InputAttribute<SelectSettings | RadioListSettings | ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.mediaPosition', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.mediaPosition', 'template-designer'),
    attribute: 'objectPosition',
    settings: {
        select: {
            ...baseSettings,
            type: 'select',
            options: alignItemsSettingOptions
        }
    },
    defaultSetting: 'select',
    editForm: () => (
        <>
            <MultiSelectForm attribute="objectPosition" options={mediaPositionFormOptions} updateSettings={objectPosition.updateSettings} />
        </>
    ),
    updateSettings: (selectedLayer, attribute, settings) =>
        DynamicLayerHelpers.updateSettingsButtonGroup(selectedLayer, attribute, settings, alignItemsSettingOptions)
};

export { objectPosition };
