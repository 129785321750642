import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { ButtonGroupSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { ButtonGroupForm } from '../forms/button-group-form';

const textJustificationOptions = [
    { key: 'LEFT_JUSTIFY', value: Translation.get('dynamicLayers.labels.textJustificationOptions.left', 'template-designer') },
    { key: 'RIGHT_JUSTIFY', value: Translation.get('dynamicLayers.labels.textJustificationOptions.right', 'template-designer') },
    { key: 'CENTER_JUSTIFY', value: Translation.get('dynamicLayers.labels.textJustificationOptions.center', 'template-designer') },
    {
        key: 'FULL_JUSTIFY_LASTLINE_LEFT',
        value: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineLeft', 'template-designer')
    },
    {
        key: 'FULL_JUSTIFY_LASTLINE_RIGHT',
        value: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineRight', 'template-designer')
    },
    {
        key: 'FULL_JUSTIFY_LASTLINE_CENTER',
        value: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineCenter', 'template-designer')
    },
    {
        key: 'FULL_JUSTIFY_LASTLINE_FULL',
        value: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineFull', 'template-designer')
    }
];

const textJustification: InputAttribute<ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.textAlign', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.textAlign', 'template-designer'),
    attribute: 'textJustification',
    settings: {
        buttonGroup: {
            type: 'buttonGroup',
            options: textJustificationOptions
        }
    },
    defaultSetting: 'buttonGroup',
    editForm: () => (
        <ButtonGroupForm
            attribute="textJustification"
            updateSettings={textJustification.updateSettings}
            options={[
                { value: 'LEFT_JUSTIFY', label: Translation.get('dynamicLayers.labels.textJustificationOptions.left', 'template-designer') },
                { value: 'RIGHT_JUSTIFY', label: Translation.get('dynamicLayers.labels.textJustificationOptions.right', 'template-designer') },
                { value: 'CENTER_JUSTIFY', label: Translation.get('dynamicLayers.labels.textJustificationOptions.center', 'template-designer') },
                {
                    value: 'FULL_JUSTIFY_LASTLINE_LEFT',
                    label: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineLeft', 'template-designer')
                },
                {
                    value: 'FULL_JUSTIFY_LASTLINE_RIGHT',
                    label: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineRight', 'template-designer')
                },
                {
                    value: 'FULL_JUSTIFY_LASTLINE_CENTER',
                    label: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineCenter', 'template-designer')
                },
                {
                    value: 'FULL_JUSTIFY_LASTLINE_FULL',
                    label: Translation.get('dynamicLayers.labels.textJustificationOptions.fullJustifyLastLineFull', 'template-designer')
                }
            ]}
        />
    ),
    updateSettings: (selectedLayer, attribute, settings) =>
        DynamicLayerHelpers.updateSettingsButtonGroup(selectedLayer, attribute, settings, textJustificationOptions)
};

export { textJustification };
