import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { HiddenSettings } from 'components/template-designer/types/dynamicLayer.type';
import { ParseValueSettingsForm } from '../forms/parse-value-settings-form';
import { baseSettings } from './base-settings';

const parseValueImage: InputAttribute<HiddenSettings> = {
    label: Translation.get('dynamicLayers.labels.parseValue', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.parseValueImage', 'template-designer'),
    attribute: 'parseValue',
    overwriteModel: 'image.url',
    settings: {
        hidden: {
            ...baseSettings,
            type: 'hidden',
            parseValue: ''
        }
    },
    defaultSetting: 'hidden',
    editForm: () => <ParseValueSettingsForm />
};

export { parseValueImage };
