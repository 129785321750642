import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { RadioListSettings, SelectSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const columnGap: InputAttribute<SliderSettings | SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.horizontalGap', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.horizontalGap', 'template-designer'),
    attribute: 'columnGap',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            min: 0,
            max: 100,
            step: 1,
            hideMarks: true,
            allowZero: true,
            inputFieldDisplay: true,
            marks: false,
            defaultValue: 0
        },
        select: {
            ...baseSettings,
            type: 'select',
            sortOptions: true,
            options: [
                {
                    key: '0',
                    value: '0'
                },
                {
                    key: '25',
                    value: '25'
                },
                {
                    key: '50',
                    value: '50'
                },
                {
                    key: '75',
                    value: '75'
                },
                {
                    key: '100',
                    value: '100'
                }
            ]
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            sortOptions: true,
            options: [
                {
                    key: '0',
                    value: '0'
                },
                {
                    key: '25',
                    value: '25'
                },
                {
                    key: '50',
                    value: '50'
                },
                {
                    key: '75',
                    value: '75'
                },
                {
                    key: '100',
                    value: '100'
                }
            ]
        }
    },
    defaultSetting: 'slider',
    editForm: () => (
        <>
            <NumberSettingsForm unit="px" />
            <MultiSelectFormDynamic unit="px" inputType="number" attribute={columnGap.attribute} updateSettings={columnGap.updateSettings} disabledIndex={0} />
        </>
    ),
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updatSettingsMultiSelectNumber(selectedLayer, attribute, settings)
};

export { columnGap };
