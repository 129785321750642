import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { AssetGalleryInputSettings, AssetSelectorSettings, DefaultSettingsFunction } from 'components/template-designer/types/dynamicLayer.type';
import defaultRatios from 'components/assets/AssetGalleryCropper/data/default-ratios';
import { AI_MODULE, UNSPLASH_MODULE } from 'components/template-designer/constants';
import Setup from 'components/data/Setup';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { generateKey } from 'components/template-designer/utils/generateKey';
import Src from 'components/template-designer/types/src.type';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { ImageSettingsForm } from '../forms/image-settings-form';
import { baseSettings } from './base-settings';
import { PredefinedImagesSettingsForm } from '../forms/predefined-images-settings-form';

const image: InputAttribute<DefaultSettingsFunction<AssetGalleryInputSettings> | DefaultSettingsFunction<AssetSelectorSettings>> = {
    label: Translation.get('dynamicLayers.labels.image', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.image', 'template-designer'),
    attribute: 'image',
    settings: {
        assetGalleryInput: () => {
            const hasAI = Setup.hasModule(AI_MODULE);
            const compressorEnabled = DynamicLayerHelpers.isCompresserEnabled();

            return {
                ...baseSettings,
                type: 'assetGalleryInput',
                fileType: ['image'],
                canUpload: true,
                canUseAiContent: hasAI,
                canRemoveBackground: hasAI,
                canRemoveObjects: hasAI,
                canMagicEdit: hasAI,
                canOutpaint: hasAI,
                canUseUnsplash: Setup.hasModule(UNSPLASH_MODULE),
                canUseAprimo: false,
                useCropper: true,
                mode: 'free',
                canUseContentSpace: true,
                useCompressor: compressorEnabled,
                isBackgroundImage: false,
                useCustomCompressor: compressorEnabled,
                ratios: defaultRatios
            };
        },
        assetSelector: (selectedLayer, attribute) => {
            const input: AssetSelectorSettings = {
                ...baseSettings,
                type: 'assetSelector',
                fileType: 'image',
                canUpload: false,
                showTitles: false,
                sourceData: []
            };

            const image = DynamicLayerHelpers.getGeneralTemplateValue(attribute, input, selectedLayer) as Src | undefined;

            if (image) {
                input.sourceData.push({
                    id: generateKey(),
                    title: image.title,
                    url: image.url
                });
            }

            return input;
        }
    },
    updateSettings: (selectedLayer, attribute, oldSettings) => {
        const input = cloneDeep(oldSettings) as AssetSelectorSettings;

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, oldSettings, selectedLayer) as Src | undefined;
        if (!generalTemplateValue) return input;

        if (!input.sourceData) input.sourceData = [];

        if (input.sourceData[0] !== undefined) return input;

        input.sourceData[0] = {
            id: generateKey(),
            title: generalTemplateValue.title,
            url: generalTemplateValue.url
        };

        return input;
    },
    defaultSetting: 'assetGalleryInput',
    editForm: () => (
        <>
            <ImageSettingsForm />
            <PredefinedImagesSettingsForm updateSettings={image.updateSettings} attribute={image.attribute} disableIndex={0} />
        </>
    )
};

export { image };
