import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { RadioListSettings, SelectSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const scale: InputAttribute<SliderSettings | SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.scale', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.scale', 'template-designer'),
    attribute: 'scale',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            endAdornment: '%',
            min: 50,
            max: 200,
            step: 10,
            allowZero: true,
            inputFieldDisplay: true,
            marks: true,
            defaultValue: 100
        },
        select: {
            ...baseSettings,
            type: 'select',
            sortOptions: true,
            options: [
                {
                    key: '50',
                    value: '50'
                },
                {
                    key: '100',
                    value: '100'
                },
                {
                    key: '150',
                    value: '150'
                },
                {
                    key: '200',
                    value: '200'
                }
            ]
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            sortOptions: true,
            options: [
                {
                    key: '0',
                    value: '0'
                },
                {
                    key: '20',
                    value: '20'
                },
                {
                    key: '40',
                    value: '40'
                },
                {
                    key: '60',
                    value: '60'
                },
                {
                    key: '80',
                    value: '80'
                },
                {
                    key: '100',
                    value: '100'
                },
                {
                    key: '120',
                    value: '120'
                },
                {
                    key: '140',
                    value: '140'
                },
                {
                    key: '160',
                    value: '160'
                },
                {
                    key: '180',
                    value: '180'
                },
                {
                    key: '200',
                    value: '200'
                }
            ]
        }
    },
    defaultSetting: 'slider',
    editForm: () => (
        <>
            <NumberSettingsForm unit="%" />
            <MultiSelectFormDynamic unit="%" inputType="number" attribute={scale.attribute} updateSettings={scale.updateSettings} disabledIndex={0} />
        </>
    ),
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updatSettingsMultiSelectNumber(selectedLayer, attribute, settings)
};

export { scale };
