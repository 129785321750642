import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { SwitchSettings } from 'components/template-designer/types/dynamicLayer.type';
import { SwitchSettingsForm } from '../forms/switch-settings-form';
import { baseSettings } from './base-settings';

const showTextShadow: InputAttribute<SwitchSettings> = {
    label: Translation.get('dynamicLayers.labels.showHideTextShadow', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.showTextShadow', 'template-designer'),
    attribute: 'showTextShadow',
    settings: {
        switch: {
            ...baseSettings,
            type: 'switch',
            isVisible: true,
            inverted: true,
            defaultValue: false
        }
    },
    defaultSetting: 'switch',
    editForm: () => (
        <SwitchSettingsForm
            label={Translation.get('dynamicLayers.layerEdit.editForms.forms.switchSettings.defaultValue', 'template-designer')}
            setting="defaultValue"
            inverted={true}
        />
    )
};

export { showTextShadow };
