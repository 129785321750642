import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { ButtonGroupSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { baseSettings } from './base-settings';

const flexDirectionOptions = [
    { key: 'row', value: Translation.get('dynamicLayers.labels.flexDirectionOptions.row', 'template-designer') },
    { key: 'column', value: Translation.get('dynamicLayers.labels.flexDirectionOptions.column', 'template-designer') }
];

const flexDirection: InputAttribute<ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.direction', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.direction', 'template-designer'),
    attribute: 'flexDirection',
    settings: {
        buttonGroup: {
            ...baseSettings,
            type: 'buttonGroup',
            options: flexDirectionOptions
        }
    },
    defaultSetting: 'buttonGroup'
};

export { flexDirection };
