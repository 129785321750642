import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { RadioListSettings, SelectSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const fontSize: InputAttribute<SliderSettings | SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.fontSize', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.fontSize', 'template-designer'),
    attribute: 'fontSize',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            min: 50,
            max: 200,
            step: 10,
            allowZero: true,
            inputFieldDisplay: true,
            marks: true,
            endAdornment: '%',
            defaultValue: 100
        },
        select: {
            ...baseSettings,
            type: 'select',
            endAdornment: '%',
            sortOptions: true,
            options: [
                {
                    key: '50',
                    value: '50'
                },
                {
                    key: '100',
                    value: '100'
                },
                {
                    key: '150',
                    value: '150'
                },
                {
                    key: '200',
                    value: '200'
                }
            ]
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            endAdornment: '%',
            sortOptions: true,
            options: [
                {
                    key: '50',
                    value: '50'
                },
                {
                    key: '100',
                    value: '100'
                },
                {
                    key: '150',
                    value: '150'
                },
                {
                    key: '200',
                    value: '200'
                }
            ]
        }
    },
    defaultSetting: 'slider',
    editForm: () => (
        <>
            <NumberSettingsForm unit="%" />
            <MultiSelectFormDynamic unit="%" inputType="number" attribute={fontSize.attribute} updateSettings={fontSize.updateSettings} disabledIndex={0} />
        </>
    ),
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updatSettingsMultiSelectNumber(selectedLayer, attribute, settings)
};

export { fontSize };
