import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { NumberSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const lineHeight: InputAttribute<SliderSettings | NumberSettings> = {
    label: Translation.get('dynamicLayers.labels.lineHeight', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.lineHeight', 'template-designer'),
    attribute: 'lineHeight',
    defaultSetting: 'slider',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            min: 0,
            max: 4,
            marks: false,
            hideMarks: true,
            inputFieldDisplay: true,
            allowZero: true,
            step: 0.1,
            defaultValue: 0
        },
        number: {
            ...baseSettings,
            type: 'number',
            placeholder: Translation.get('dynamicLayers.labels.lineHeight', 'template-designer'),
            min: 0,
            max: 4,
            step: 0.1
        }
    },
    editForm: () => (
        <>
            <NumberSettingsForm unit="%" />
        </>
    )
};

export { lineHeight };
