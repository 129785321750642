import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { AlertSettings } from 'components/template-designer/types/dynamicLayer.type';
import { baseSettings } from './base-settings';
import { AlertSettingsForm } from '../forms/alert-settings-form';

const alert: InputAttribute<AlertSettings> = {
    label: Translation.get('dynamicLayers.labels.alert', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.alert', 'template-designer'),
    attribute: 'alert',
    settings: {
        alert: {
            ...baseSettings,
            type: 'alert',
            alertType: 'info',
            message: Translation.get('dynamicLayers.labels.exampleMessage', 'template-designer')
        }
    },
    defaultSetting: 'alert',
    editForm: () => <AlertSettingsForm />
};

export { alert };
