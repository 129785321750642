import React from 'react';
import { ButtonGroupSettings, RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import cloneDeep from 'helpers/cloneDeep';
import { ButtonGroupForm, ButtonGroupFormOption } from '../forms/button-group-form';
import { baseSettings } from './base-settings';

const alignItemsSettingOptions = [
    { key: 'flex-start', value: Translation.get('general.position.left', 'template-designer') },
    { key: 'center', value: Translation.get('general.position.center', 'template-designer') },
    { key: 'flex-end', value: Translation.get('general.position.right', 'template-designer') }
];

const alignItemsFormOptions: ButtonGroupFormOption[] = [
    {
        value: 'flex-start',
        label: Translation.get('general.position.left', 'template-designer'),
        icon: 'align-horizontal-left'
    },
    {
        value: 'center',
        label: Translation.get('general.position.center', 'template-designer'),
        icon: 'align-horizontal-center'
    },
    {
        value: 'flex-end',
        label: Translation.get('general.position.right', 'template-designer'),
        icon: 'align-horizontal-right'
    },
    {
        value: 'space-between',
        label: Translation.get('dynamicLayers.labels.alignItemsOptions.between', 'template-designer'),
        icon: 'align-flex-space-between'
    },
    {
        value: 'space-evenly',
        label: Translation.get('dynamicLayers.labels.alignItemsOptions.evenly', 'template-designer'),
        icon: 'align-flex-space-evenly'
    }
];

const alignItems: InputAttribute<SelectSettings | RadioListSettings | ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.alignHorizontal', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.alignItems', 'template-designer'),
    attribute: 'alignItems',
    settings: {
        buttonGroup: {
            ...baseSettings,
            type: 'buttonGroup',
            options: alignItemsSettingOptions
        }
    },
    defaultSetting: 'buttonGroup',
    updateSettings: (selectedLayer, attribute, oldSettings) => {
        const input = cloneDeep(oldSettings) as SelectSettings | RadioListSettings | ButtonGroupSettings;

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, oldSettings, selectedLayer) as string | undefined;

        const optionExists = input.options.some((option) => option.key === generalTemplateValue);
        if (!optionExists) {
            const newOption = alignItemsSettingOptions.find((option) => option.key === generalTemplateValue);
            if (newOption) {
                const newOptions = cloneDeep(input.options);
                newOptions.push(newOption);
                const sortedNewOptions: SelectSettings['options'] = [];

                alignItemsSettingOptions.forEach((option) => {
                    if (newOptions.some((newOption) => newOption.key === option.key)) {
                        sortedNewOptions.push(option);
                    }
                });

                input.options = sortedNewOptions;
            }
        }

        return input;
    },
    editForm: () => (
        <>
            <ButtonGroupForm attribute="alignItems" options={alignItemsFormOptions} updateSettings={alignItems.updateSettings} />
        </>
    )
};

export { alignItems };
