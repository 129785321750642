import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { ButtonGroupSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { ButtonGroupForm } from '../forms/button-group-form';
import { baseSettings } from './base-settings';

const textAlignOptions = [
    { key: 'left', value: Translation.get('dynamicLayers.labels.textAlignOptions.left', 'template-designer') },
    { key: 'center', value: Translation.get('dynamicLayers.labels.textAlignOptions.center', 'template-designer') },
    { key: 'right', value: Translation.get('dynamicLayers.labels.textAlignOptions.right', 'template-designer') },
    { key: 'justify', value: Translation.get('dynamicLayers.labels.textAlignOptions.justify', 'template-designer') }
];

const textAlign: InputAttribute<ButtonGroupSettings> = {
    label: Translation.get('dynamicLayers.labels.textAlign', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.textAlign', 'template-designer'),
    attribute: 'textAlign',
    settings: {
        buttonGroup: {
            ...baseSettings,
            type: 'buttonGroup',
            options: textAlignOptions
        }
    },
    defaultSetting: 'buttonGroup',
    editForm: () => (
        <ButtonGroupForm
            attribute="textAlign"
            updateSettings={textAlign.updateSettings}
            options={[
                {
                    icon: 'format_align_left',
                    label: Translation.get('dynamicLayers.labels.textAlignOptions.left', 'template-designer'),
                    value: 'left'
                },
                {
                    icon: 'format_align_center',
                    label: Translation.get('dynamicLayers.labels.textAlignOptions.center', 'template-designer'),
                    value: 'center'
                },
                {
                    icon: 'format_align_right',
                    label: Translation.get('dynamicLayers.labels.textAlignOptions.right', 'template-designer'),
                    value: 'right'
                },
                {
                    icon: 'format_align_justify',
                    label: Translation.get('dynamicLayers.labels.textAlignOptions.justify', 'template-designer'),
                    value: 'justify'
                }
            ]}
        />
    ),
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updateSettingsButtonGroup(selectedLayer, attribute, settings, textAlignOptions)
};

export { textAlign };
