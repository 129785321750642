import React from 'react';
import Translation from 'components/data/Translation';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { NumberSettings, RadioListSettings, SelectSettings, TextSelectSettings, TextSettings } from 'components/template-designer/types/dynamicLayer.type';
import cloneDeep from 'helpers/cloneDeep';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { TemplateData } from 'components/template-designer/types/template.type';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';
import TextSettingsForm from '../forms/text-settings-form';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const text: InputAttribute<TextSettings | NumberSettings | RadioListSettings | SelectSettings | TextSelectSettings> = {
    label: Translation.get('dynamicLayers.labels.text', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.text', 'template-designer'),
    dataCy: 'templateDesigner-feedMappingAddTextAttribute-button',
    attribute: 'text',
    settings: {
        text: {
            ...baseSettings,
            type: 'text',
            placeholder: Translation.get('dynamicLayers.dynamicLayerEdit.placeholders.overwriteDesign', 'template-designer'),
            maxLength: 50,
            multiline: true,
            useValueObject: false,
            languageSpecific: false
        },
        textMultiLanguage: {
            ...baseSettings,
            type: 'textMultiLanguage',
            placeholder: Translation.get('dynamicLayers.dynamicLayerEdit.placeholders.overwriteDesign', 'template-designer'),
            maxLength: 50,
            multiline: true,
            useValueObject: true,
            languageSpecific: false
        },
        number: {
            ...baseSettings,
            languageSpecific: false,
            type: 'number',
            placeholder: Translation.get('dynamicLayers.dynamicLayerEdit.placeholders.overwriteDesign', 'template-designer'),
            min: 0,
            max: 100,
            step: 1
        },
        select: {
            ...baseSettings,
            type: 'select',
            languageSpecific: false,
            showEmptyOption: true,
            options: [
                {
                    key: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer'),
                    value: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer')
                }
            ]
        },
        selectMultiLanguage: {
            ...baseSettings,
            type: 'selectMultiLanguage',
            languageSpecific: false,
            showEmptyOption: true,
            options: [
                {
                    key: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer'),
                    value: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer')
                }
            ]
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            languageSpecific: false,
            options: [
                {
                    key: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer'),
                    value: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer')
                }
            ]
        },
        radioListMultiLanguage: {
            ...baseSettings,
            type: 'radioListMultiLanguage',
            languageSpecific: false,
            options: [
                {
                    key: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer'),
                    value: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer')
                }
            ]
        },
        textSelect: {
            ...baseSettings,
            type: 'textSelect',
            selectType: 'radioList',
            showEmptyOption: true,
            maxLength: 50,
            multiline: true,
            languageSpecific: false,
            options: [
                {
                    key: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer'),
                    value: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer')
                }
            ]
        },
        textSelectMultiLanguage: {
            ...baseSettings,
            type: 'textSelectMultiLanguage',
            selectType: 'radioList',
            showEmptyOption: true,
            maxLength: 50,
            multiline: true,
            languageSpecific: false,
            options: [
                {
                    key: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer'),
                    value: Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.putYourTextHere', 'template-designer')
                }
            ]
        }
    },
    defaultSetting: 'textMultiLanguage',
    updateSettings: (selectedLayer, attribute, oldSettings) => {
        const input = cloneDeep(oldSettings) as TextSettings | NumberSettings | RadioListSettings | SelectSettings | TextSelectSettings;
        if (input.type !== 'select' && input.type !== 'radioList' && input.type !== 'textSelect' && input.type !== 'textSelectMultiLanguage') return input;

        const generalTemplateValue = DynamicLayerHelpers.getGeneralTemplateValue(attribute, oldSettings, selectedLayer) as string | undefined;
        if (!generalTemplateValue) return input;

        if (!input.options) input.options = [];
        input.options[0] = {
            key: generalTemplateValue,
            value: generalTemplateValue
        };
        return input;
    },
    editForm: () => {
        const templateType = getTemplateData<TemplateData['type']>('templateData.type');

        return (
            <>
                <NumberSettingsForm />
                <MultiSelectFormDynamic
                    inputType="text"
                    attribute={text.attribute}
                    updateSettings={text.updateSettings}
                    multiline
                    disabledIndex={['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType) ? undefined : 0}
                    defaultCharacterLimit={50}
                />
                <TextSettingsForm defaultCharacterLimit={50} />
            </>
        );
    }
};

export { text };
