import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { NumberSettings, RadioListSettings, SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const frameDuration: InputAttribute<NumberSettings | SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.frameDuration', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.frameDuration', 'template-designer'),
    attribute: 'frameDuration',
    defaultSetting: 'number',
    settings: {
        number: {
            ...baseSettings,
            type: 'number',
            placeholder: Translation.get('dynamicLayers.labels.frameDuration', 'template-designer'),
            min: 1000,
            max: 20000,
            step: 1000
        },
        select: {
            ...baseSettings,
            type: 'select',
            sortOptions: true,
            options: [
                {
                    key: '1000',
                    value: '1000'
                },
                {
                    key: '3000',
                    value: '3000'
                },
                {
                    key: '5000',
                    value: '5000'
                },
                {
                    key: '8000',
                    value: '8000'
                }
            ]
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            sortOptions: true,
            options: [
                {
                    key: '1000',
                    value: '1000'
                },
                {
                    key: '3000',
                    value: '3000'
                },
                {
                    key: '5000',
                    value: '5000'
                },
                {
                    key: '8000',
                    value: '8000'
                }
            ]
        }
    },
    editForm: () => {
        return (
            <>
                <NumberSettingsForm unit="ms" />
                <MultiSelectFormDynamic
                    unit="ms"
                    inputType="number"
                    attribute={frameDuration.attribute}
                    updateSettings={frameDuration.updateSettings}
                    disabledIndex={0}
                />
            </>
        );
    },
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updatSettingsMultiSelectNumber(selectedLayer, attribute, settings)
};

export { frameDuration };
